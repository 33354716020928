import { createRouter, createWebHistory } from "vue-router";
import Body from "@/components/Body.vue";
import HomeView from "@/views/HomeView.vue";
import PrivacyPolicy from "@/views/Privacypolicy.vue";
import Terms from "@/views/Terms.vue";
import Faq from "@/views/Faq.vue";
import Support from "@/views/Support.vue";

const routes = [
  {
    path: "/",
    component: Body,
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
    ],
  },
  {
    path: "/privacy-policy",
    component: Body,
    children: [
      {
        path: "/privacy-policy",
        name: "Policy",
        component: PrivacyPolicy,
      },
    ],
  },
  {
    path: "/terms-conditions",
    component: Body,
    children: [
      {
        path: "/terms-conditions",
        name: "terms",
        component: Terms,
      },
    ],
  },
  {
    path: "/faq",
    component: Body,
    children: [
      {
        path: "/faq",
        name: "faq",
        component: Faq,
      },
    ],
  },
  {
    path: "/support",
    component: Body,
    children: [
      {
        path: "/support",
        name: "support",
        component: Support,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
