<template>
  <nav class="navbar navbar-expand-lg" :class="{ 'scroll-bg': bg }">
    <div class="container">
      <a class="navbar-brand fs-4" href="/">
        <img src="../assets/images/logo.png" class="navbar-logo" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="sidebar offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header text-white">
          <button
            type="button"
            class="btn-close btn-close-white shadow-none"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body d-flex flex-column flex-lg-row p-4 p-lg-0">
          <div
            class="menu-container text-center"
            :class="{ 'border-none': bg, 'bg-transparent': bg }"
          >
            <a
              href="/#home"
              class="menu-item mx-2"
              @click="setActive(0)"
              :class="{ active: activeIndex === 0 }"
              >Home</a
            >
            <a
              href="#about-us"
              class="menu-item mx-2"
              @click="setActive(1)"
              :class="{ active: activeIndex === 1 }"
              >About us</a
            >
            <a
              href="#how-it-works"
              class="menu-item mx-2"
              @click="setActive(2)"
              :class="{ active: activeIndex === 2 }"
              >How It Works</a
            >
            <a
              href="#testimonials"
              class="menu-item mx-2"
              @click="setActive(3)"
              :class="{ active: activeIndex === 3 }"
              >Testimonial</a
            >
          </div>
          <div class="d-flex justify-content-between dropdown mb-lg-0">
            <a
              class="header-btn btn btn-primary btn-sm border-0 bgc-secondary d-flex justify-content-between align-items-center"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="">Get Started</span>
              <img
                class=""
                src="../assets/images/header-drop-arrow.png"
                alt=""
              />
            </a>
            <ul
              class="dropdown-menu dropdown-menu-dark bgc-secondary py-0 mb-5"
            >
              <li>
                <a class="dropdown-item" :href="adminUrl" target="_blank"
                  >Login as Admin</a
                >
              </li>
              <hr class="my-0" />
              <li>
                <a class="dropdown-item" :href="clientUrl" target="_blank"
                  >Login as User</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      bg: false,
      activeIndex: null,
      adminUrl: process.env.VUE_APP_ADMIN_URL,
      clientUrl: process.env.VUE_APP_CLIENT_URL,
    };
  },
  mounted() {
    // Add scroll event listener when the component is mounted
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 125) {
        this.bg = true;
      } else {
        this.bg = false;
      }
    },
    setActive(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style>
nav {
  position: sticky !important;
  top: 0;
  width: 100%;
  height: 125px;
  z-index: 100;
  transition: background-color 0.3s ease-in-out;
}

.scroll-bg {
  background-color: rgba(255, 255, 255) !important;
}

.border-none {
  border: none !important;
}

.bg-transparent {
  background-color: transparent;
}

.header-btn {
  border-radius: 50px !important;
  height: 69px !important;
  width: 160px !important;
  line-height: 60px !important;
  font-weight: 600 !important;
  padding-inline: 28px !important;
}

.header-btn img {
  width: 9.5px;
  height: 6.6px;
}

.dropdown .dropdown-item {
  color: white !important;
  padding: 10px 10px;
  font-size: 14px !important;
}
</style>
