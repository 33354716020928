import axiosInstance from "@/helpers/axios";

export default {
    namespaced: true,
    actions: {
        faqGetAll({ dispatch, commit }) {
            return new Promise((resolve, reject) => {
                axiosInstance.get("/faq").then((response) => {
                    resolve(response.data);
                }).catch((effect) => {
                    reject(effect.response.data);
                })
            });
        },
    },
}