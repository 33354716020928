import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.min.js'
import "@/assets/style.css"
import "@/assets/responsive.css"
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';



createApp(App)
    .use(store)
    .use(router)
    .component("loading", Loading)
    .mount('#app')
