import { createStore } from 'vuex'
import Home from "./modules/home.js"
import Faq from "./modules/faq.js"
import Setting from "./modules/setting.js"

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Home,
    Faq,
    Setting,
  },
})
