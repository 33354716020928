import axios from "axios"

const axiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_URL}`,
});
axiosInstance.interceptors.request.use((request) => {
    request.headers = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
    };
    return request;
})


export default axiosInstance;