import axiosInstance from "@/helpers/axios"

export default {
    namespaced: true,
    actions: {
        homeGetAll({ dispatch, commit }) {
            return new Promise((resolve, reject) => {
                axiosInstance.get("/website-content").then((response) => {
                    resolve(response.data);
                })
                    .catch((e) => {
                        reject(e.response.data);
                    })
            })
        }
    }
}