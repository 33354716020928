<template>

  <loading v-if="loading" :active="loading" :is-full-page="fullPage" loader="bars"></loading>
  <router-view />
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.loading = !this.loading
      }, 1000);
      this.loading = !this.loading
    },
  },
  mounted() {
    this.loading = false;
  }
}
</script>
