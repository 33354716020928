<template>
    <div class="container mt-md-5 mt-sm-4 mt-2 privacy-policy ">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <h1 class="text-center mb-sm-5 mb-3 color-primary">Terms & Conditions</h1>
        <p v-html="terms" id="white-color"></p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            terms: null,
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loading = true;
            const key = "terms";
            this.$store.dispatch("Setting/get", { key }).then((response) => {
                this.loading = false;
                this.terms = response.data.value
            }).catch((effect) => {
                console.log(effect);
                this.loading = false;
            })
        }
    }
}
</script>