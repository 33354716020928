<template>
  <div class="container text-white">
    <h1 class="text-center my-lg-5 my-md-4 my-sm-3 mt-0 mb-3 color-primary">FAQs</h1>
    <div class="accordion" id="accordionExample">
      <div class="accordion-item" v-for="(faq, index) in faqs">
        <h2 class="accordion-header">
          <button
            class="accordion-button bgc-primary"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapseOne' + faq.id"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            {{ faq.question }}
          </button>
        </h2>
        <div
          :id="'collapseOne' + faq.id"
          class="accordion-collapse collapse"
          :class="{ show: index == 0 }"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            {{ faq.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("Faq/faqGetAll")
        .then((response) => {
          this.loading = false;
          this.faqs = response.data;
        })
        .catch((effect) => {
          console.log(effect);
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.accordion-item {
  background-color: transparent !important;
  border: none !important;
}

.accordion-button {
  border-radius: 68px !important;
  border: none !important;
  margin-bottom: 20px;
  font-size: 20px !important;
  color: #fff !important;
  padding: 28px !important;
}

.accordion-body {
  border: 1px solid #fff;
  border-radius: 16px;
  margin-bottom: 20px;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  box-shadow: none !important;
}
</style>
