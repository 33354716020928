<template>
    <div class="support-container px-sm-2 px-3 mx-auto">
        <!-- <loading :active="loading" :is-full-page="true" loader="bars"></loading> -->
        <div class="row g-0 text-center mb-sm-0 mb-5">
            <h2 class="support-h3 mb-4">Support</h2>
        </div>
        <!-- support Forms -->
        <form>
            <div class="form-group mb-sm-4 mb-3">
                <label class="support-form-label">
                    Name
                </label>
                <input type="text" class="support-form-control form-control" placeholder="Name" required autofocus
                    autocomplete="off" />
            </div>
            <div class="form-group mb-sm-4 mb-3">
                <label class="support-form-label">
                    Email
                </label>
                <input type="text" class="support-form-control form-control" placeholder="info@gmail.com" required
                    autofocus autocomplete="off" />
            </div>
            <div class="form-group mb-sm-5 mb-4">
                <label for="InputPassword1" class="support-form-label">
                    Details
                </label>
                <textarea class="support-form-control-textarea form-control" placeholder="Write here..."
                    required></textarea>
            </div>
            <!-- support Button -->
            <div class="mb-sm-5 mb-4">
                <button type="submit" class="support-button">Submit</button>
            </div>
        </form>
    </div>
</template>

<style>
/* Support */

.support-container {
    max-width: 726px;
}

.support-p {
    font-size: 16px;
    font-weight: lighter;
}

.support-form-control {
    height: 60px;
    background: #1f1f1f;
    border: 1px solid var(--emp-primary-color) !important;
    border-radius: 68px !important;
    color: #fff;
    padding-left: 26px;
}

.support-form-control-textarea {
    height: 160px;
    background: transparent !important;
    border: 1px solid var(--emp-primary-color) !important;
    border-radius: 16px;
    color: #fff;
    padding: 26px;
}

.support-form-control-textarea::placeholder {
    color: #8c8c8c;
}

.support-form-control[type="text"],
.support-form-control[type="email"] {
    background-color: transparent !important;
    color: #fff;
}

.support-form-label {
    margin-bottom: 1px;
    font-size: 18px;
    font-weight: 400;
}

.support-form-control::placeholder {
    color: #8c8c8c;
    padding-left: 25px;
    font-size: 18px;
    font-weight: 400;
}

/* support Button */
.support-button {
    width: 100%;
    height: 80px;
    background-color: var(--emp-primary-color);
    border: none;
    border-radius: 50px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
}
</style>