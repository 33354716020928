<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <section id="home">
    <div
      class="container text-center mt-sm-5 mt-2 d-flex flex-column align-items-center"
    >
      <h1 class="display-4 w-50 color-primary">
        {{ api.section1_title }}
      </h1>
      <p class="lead w-75">
        {{ api.section1_description }}
      </p>
      <div class="d-flex">
        <a
          href="https://api.employmentx.com.pk/EmpX.apk"
          class="btn btn-lg bgc-secondary text-white text-center"
          style="height: 60px; line-height: 38px;"
          target="_blank"
        >
          Download App
          <i
            class="fa-solid fa-arrow-right"
            style="color: #ffffff; margin-left: 15px"
          ></i>
        </a>
        <!-- <span class="d-inline-block" style="color: #525252; margin-left: 40px; height: 60px; line-height: 60px; font-size: 20px">
          Learn more
        </span> -->
      </div>
    </div>
    <div class="container mt-5">
      <img
        src="../assets/images/Group 1000003459.png"
        class="img-fluid"
        style="width: 100%"
      />
    </div>
  </section>

  <section class="mb-5">
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-md-4" v-for="section2 in api.section2">
          <div class="feature-box p-4">
            <div class="feature-icon">
              <img :src="section2.image_url" />
            </div>
            <h6>{{ section2.title }}</h6>
            <p class="pe-3">
              {{ section2.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="grad2"></div>
    <div class="container" id="about-us">
      <div class="row">
        <div class="col-md-6">
          <h2
            class="paragraph color-primary"
            style="font-weight: 100 !important; margin-top: 18%"
          >
            {{ api.section3_title }}
          </h2>
          <p class="content" style="color: #525252; font-size: 20px">
            {{ api.section3_description }}
          </p>
          <a
            href="https://api.employmentx.com.pk/EmpX.apk"
            class="btn btn-lg bgc-secondary text-white text-center"
            style="height: 60px; line-height: 38px;"
            target="_blank"
          >
            Download App
            <i
              class="fa-solid fa-arrow-right"
              style="color: #ffffff; margin-left: 15px"
            ></i>
          </a>
        </div>
        <div class="col-md-6">
          <img
            src="../assets/images/Group 1000003451.png"
            class="img"
            style="width: 100%"
          />
        </div>
      </div>
    </div>
    <div class="container stats-panel">
      <div class="row">
        <div
          class="col-lg-4 stats-item d-flex align-items-center"
          v-for="section4 in api.section4"
        >
          <h2 class="stats color-secondary">{{ section4.title }}</h2>
          <p class="mb-0 lh-1" style="font-size: 20px; color: #525252">
            {{ section4.description }}
          </p>
        </div>
      </div>
    </div>
    <div class="container text-center mt-5">
      <h1 class="display-4 color-primary">
        {{ api.section5_title }}
      </h1>
      <p class="lead" style="color: #525252; font-size: 20px">
        {{ api.section5_description }}
      </p>
    </div>
    <div id="grad3"></div>
    <div class="row mx-0 px-lg-2 ps-lg-0">
      <div class="col-lg-7 mb-lg-0 mb-3 p-5">
        <img src="../assets/images/Group 1000003653.png" style="width: 100%" />
      </div>
      <div class="col-xl-4 col-lg-5 px-2 align-content-center">
        <div
          class="d-flex mb-3"
          :class="{
            'mb-xl-5': index + 1 < marginB,
            'mb-lg-4': index + 1 < marginB,
            'mb-lg-0': index + 1 == marginB,
          }"
          v-for="(section6, index) in api.section6"
        >
          <div
            class="about-us-number-bg bgc-secondary text-white d-flex justify-content-center align-items-center"
          >
            <h5 class="mb-0">{{ index + 1 }}</h5>
          </div>
          <div style="padding-left: 4%">
            <h6 class="text color-primary" style="font-size: 24px">
              {{ section6.title }}
            </h6>
            <h5 style="color: gray" class="mb-0">{{ section6.description }}</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="how-it-works" v-if="api.video_file">
    <div class="container text-center">
      <h1 class="display-4 color-primary">How it Works</h1>
    </div>
    <div class="container">
      <video
        id="video"
        v-if="api.video_file"
        :src="api.video_file.file_url"
        class="video"
        style="margin-top: 5%; width: 100%"
        controls
        v-show="videoShow"
      ></video>
    </div>
  </section>
  <section id="testimonials">
    <div class="container text-center mt-5">
      <h1 class="display-4 color-primary" style="margin-top: 10% !important">
        Testimonial
      </h1>
      <carousel
        id="gallery"
        :items-to-show="1"
        :wrap-around="false"
        v-model="currentSlide"
        class="position-relative"
      >
        <slide
          v-for="(section8, index) in api.section8"
          :key="index + 1"
          class="d-flex flex-column"
        >
          <img
            src="../assets/images\Group 1000003417.png"
            class="comma-right position-absolute"
          />
          <p
            class="w-50 position-relative"
            style="color: gray; font-size: 15px; line-height: 1.5em"
          >
            {{ section8.testimonial }}
          </p>
          <img
            src="../assets/images\Group 1000003418.png"
            class="comma-left position-absolute"
          />
          <div class="text-center">
            <h4 class="color-primary" style="margin-top: 2%">
              {{ section8.name }}
            </h4>
            <p style="color: grey">{{ section8.designation }}</p>
          </div>
        </slide>
      </carousel>
      <div
        class="testimonial-pic container text-center d-flex flex-column align-items-center"
      >
        <div class="w-25">
          <Carousel
            id="thumbnails"
            :items-to-show="3"
            :wrap-around="true"
            v-model="currentSlide"
            ref="carousel"
          >
            <Slide v-for="(section8, index) in api.section8" :key="index + 1">
              <div class="carousel__item" @click="slideTo(index + 1 - 1)">
                <img :src="section8.image_url" />
              </div>
            </Slide>
          </Carousel>
        </div>
      </div>
    </div>

    <!-- <div class="container text-center w-25">
      <Carousel id="thumbnails" :items-to-show="3" :wrap-around="true" v-model="currentSlide" ref="carousel">
        <Slide v-for="slide in 3" :key="slide">
          <div class="carousel__item" @click="slideTo(slide - 1)"> <img src="../assets/images\Ellipse 195.png" /></div>
        </Slide>
      </Carousel>
    </div> -->
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      api: [],
      videoShow: false,
      currentSlide: 0,
      marginB: null,
      loading: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      await this.$store
        .dispatch("Home/homeGetAll")
        .then((response) => {
          this.loading = false;
          this.api = response.data;
          this.videoShow = true;
          console.log(this.api);
          this.marginB = this.api.section6.length;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    slideTo(val) {
      this.currentSlide = val;
    },
  },
};
</script>

<style>
.comma-right {
  left: 15% !important;
  top: 0;
}

.comma-left {
  right: 15% !important;
  bottom: 80px;
}

.carousel__slide--active .carousel__item img {
  border-radius: 50px;
  transition: border 0.4s ease-in;
}

.carousel__item img {
  width: 69px;
  height: 69px;
  border-radius: 50px;
}

.about-us-number-bg {
  height: 43px;
  min-width: 43px !important;
  background-color: rgba(84, 36, 253);
  border-radius: 50px;
}
</style>
